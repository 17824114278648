import { ActionReducerMap } from '@ngrx/store';
import { IState, pagesReducer } from './pages.reducer';
import { IPresalesState, presalesReducer } from './presales.reducer';
import { IProfilingState, profilingReducer } from './profiling.reducer';

export interface IAppState {
	parametros: any;
	usuario: any;
	pages: IState;
	profiling: IProfilingState;
	presales: IPresalesState;
	vinculacionPID?: any;
}

export const appReducers: ActionReducerMap<IAppState> = {
	parametros: null,
	usuario: null,
	pages: pagesReducer,
	profiling: profilingReducer,
	presales: presalesReducer,
	vinculacionPID: null,
};
