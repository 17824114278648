<div class="container-fluid initial-container">

    <div class="row">
      <div class="col-lg-7 col-md-7 col-xs-12 container-marketing">

        <div class="row">
          <div class="col container-logo">
            <div class="logo-banco">&nbsp;</div>
            <div class="logo-super">&nbsp;</div>
          </div>
        </div>

        <div class="row">
          <div class="container-footer">
              <p class="text-copy">© Todos los Derechos Reservados. www.bancodeoccidente.com.co</p>
              <div class="logo-aval">&nbsp;</div>

          </div>
        </div>

      </div>

      <!-- Container login -->
      <div class="col-lg-5 col-md-5 col-xs-12 container-login">

        <div class="row">
          <div class="col container-logo-fidu">
            <div class="logo-fidu">&nbsp;</div>
          </div>
        </div>

        <div class="row ">
          <div class="col">
            <p class="texto-bienvenida">Bienvenido a la vinculación 100% digital de Fiduoccidente. Accede según tu perfil para que puedas vincular a tu cliente a uno de nuestros productos</p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p class="texto-ingreso">Puedes ingresar como</p>
          </div>
        </div>

        <div class="row">
          <div class="col btn-container">
            <div class="btn-login btn-director" (click)="onLogin(fiduciaria, workFlow.FONDOS)">&nbsp;</div>
            <div class="btn-login btn-funcionario" (click)="onLogin(banco, workFlow.FONDOS)">&nbsp;</div>
            <div class="btn-login btn-asesor" (click)="onLogin(fiduciaria, workFlow.PREVENTAS)">&nbsp;</div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p class="texto-terminos">Al ingresar a la herramienta estás aceptando los <span class="texto-link" id="term-condition-a" (click)="toogleModal()">Términos y condiciones</span></p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p class="texto-descarga">Si requieres descargar los formularios de vinculación <span class="texto-link" id="term-descargar-a" (click)="onLogin(fiduciaria, workFlow.GESTIONVINCULACION)">Ingresa aquí</span></p>
          </div>
        </div>
      </div>
    </div>

    <!-- footer only mobile -->
    <div class="row">
      <div class="col container-footer-mobile">
        <div class="row">
          <div class="col container-logo-aval"><div class="logo-aval">&nbsp;</div></div>
        </div>
        <div class="row">
          <div class="col">
            <p class="text-copy-mobile">Privacidad · Términos y condiciones</p>
          </div>
        </div>
        <div class="row">
          <div class="col container-logo-super-mobile">
            <div class="logo-super-mobile">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>

</div>

<app-modal #modal [parametrosModal]='parametrosModal'></app-modal>

<ng-template #mantenimientoModal let-modal>

  <div class="container-icon">
    <div class="maintenance-icon">&nbsp;</div>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="col title-maintenance">
        <p>FLUJO DESHABILITADO</p>
      </div>
    </div>
    <div class="row">
      <div class="col message-maintenance">
        <p>El flujo selecccionado no está disponible. Inténtalo más tarde </p>
      </div>
    </div>
    <div class="row">
      <div class="col d-grid gap-2">
        <button id="btn-modal-maint" type="button" class="btn btn-secondary" class="btn btn-primary" (click)="closeMantenimientoMdl()">Cerrar</button>
      </div>
    </div>
  </div>
</ng-template>
