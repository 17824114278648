export const environment = {
	production: false,
	backEndUrl: 'https://w76h2g7o60.execute-api.us-east-2.amazonaws.com/dev',
	// auth configuration variables
	authUrl: 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_rJG8HWuQN',
	strictDiscoveryDocumentValidation: false,
	redirectUri: 'https://focc-pid-dev-front.avaldigitallabs.com/pid/inicio',
	clientId: 'bm62mt3fu3h4rrc1e9koovffb',
	scope: 'email openid profile',
	responseType: 'code',
	customQueryParams: { identity_provider: 'focc-pid-dev-provider' },
	customQueryParamsBO: { identity_provider: 'focc-pid-dev-bo-provider' },
	showDebugInformation: true,
	sessionChecksEnabled: false,
	postLogoutRedirectUri: 'https://focc-pid-dev-front.avaldigitallabs.com/pid/',
	// suffix: 'bb-ent-clients',
	rutaAppsettings: './assets/pid/appsettings.json',
	rutaParametros: './assets/pid/parametros.json',
	urlSocket: 'wss://9e1r6milol.execute-api.us-east-2.amazonaws.com/dev',
	freshChatToken: '7941d516-85c9-433f-85a9-0e2333090027',
	freshChatHost: 'https://wchat.freshchat.com',
	gAtrackCode: 'WdzVDSu7YbG_dgCJuLb0aQ&gtm_preview=env-5',
	siteKeyRecaptchaV3: '6LdxVRMcAAAAAOcxltxdEQXLlmrVCH9oniGwYJH0',
	enableCaptcha: true,
	rutaPoliticas: './assets/pid/fondos/politicas.html',
	tablaDynamoFondos: 'focc-pid-dev-fondos',
	identityPoolId: 'us-east-2:f5adbb5c-7273-446a-b1ab-590f19cbe45b',
	AWSConfigRegion: 'us-east-2',
	cookieSecure: true,
	datadogEnable: false,
	datadogClientToken: 'pube843ec33b20792a93ea3300e09ac8c6b',
	datadogAppId: '93b6f3bb-808b-4eb1-9fa1-b91575b3548c',
	rutaRulesEngine: './assets/rules/rules-engine.json',
	tealiumAccount: 'adl',
	tealiumProfile: 'occidente',
	tealiumEnvironment: 'dev',
	folderPoliticas: 'focc-pid-dev-common-resources',
	presalesMultiClient: true,
	isTestLibrary: false,
	callPaymentPlan: true,
	workFlowByLibrary: true,
	maintenanceToken: 'pqZD3HqiJV2bRktTR0b6D4LVocbVTcY39jscyMqS',
	urlCDN: 'https://focc-pid-dev-cdn.avaldigitallabs.com/imagenes',
	keyUrl: 'dEqRJuHf7K1qQPpQ3KdFx7zqIaFAKFhs7jbFdB1r',
	numberProducts: 3,
	inactivityTime: 300000,
};
