import { ActionReducer, INIT } from '@ngrx/store';
import { Constantes } from '../../../app/shared/enums/index';
import * as reducers from '../reducers/index';

export function persistMetaReducer(
	reducer: ActionReducer<any>,
): ActionReducer<any> {
	return (state, action) => {
		if (action.type === INIT) {
			const storageValue = sessionStorage.getItem('state');
			if (storageValue) {
				try {
					return JSON.parse(storageValue);
				} catch {
					sessionStorage.removeItem('state');
				}
			}
		} else if (action.type === Constantes.NEWPRODUCT) {
			state = reducers.initialProfilingState;
		}

		const nextState = reducer(state, action);
		sessionStorage.setItem('state', JSON.stringify(nextState));
		return nextState;
	};
}
