export enum EstadosRespuesta {
	OK = 'OK',
}

export enum MedioEnvioOTP {
	SMS = 'SMS',
	CALL = 'CALL',
}

export enum EstadosRespuestaSocket {
	CONNECTION = 200,
	CREARVINCULACION = 201,
	PDFGENERADO = 213,
	VINCULACIONEXITOSA = 214,
	PDFFIRMADO = 215,
	VALIDARPEP = 321,
	NOOBJETIVO = 326,
	NEGOCIO = 300,
	TECNICOS = 400,
	OTPCORRECTA = 212,
	PDFEXITOSO = 224,
	OTPFALLIDA = 312,
	FALLOVINCULACION = 314,
	OTPIMPOSIBLE = 315,
	NOINFOFINANCIERA = 317,
	FALLOFIRMADIGITAL = 320,
	PDFNOEXISTE = 330,
	URLRESTRICT = 331,
	SERVER = 401,
	SERVERMAREIGUA = 402,
	SERVER500 = 500,
	NOCONEXIONSVC = 510,
	DESCONEXIONSVC = 512,
	NOROL = 514,
	ERRORDYNAMODB = 515,
	ERRORS3 = 516,
}

export enum Constantes {
	CLIENTCODE = '035',
	DEFECTPROFILE = 'no_data',
	SI = 'Si',
	NO = 'No',
	NEWPRODUCT = '[Product] New Product',
	VIEW = 'view',
	INIT = 'init',
	URLTAGS = 'https://tags.tiqcdn.com/utag/',
	PROFILECOORDVINCU = 'focc-pid-coordinacion-vinculaciones-role',
	ACEPTAACUERDOFIRMA = 'ACEPTA_ACUERDO_FIRMA',
	ACEPTADECLARACIONTRATAMIENTODATOS = 'ACEPTA_DECLARACION_TRATAMIENTO_DATOS',
	S = 'S',
	N = 'N',
	URLPEP = '/pep',
	URLSOLICITAPDF = '/gestion-vinculacion/solicitar-pdf',
	BUCKETDOCUMENTOS = 'focc-pid-dev-common-resources',
	FOLDERPOLITICAS = 'politicas/tratamiento-datos.html',
	OFFICEDEFAULT = '2020',
	PROFILEPRESALES = 'focc-pid-comerciales-sala-ventas-role',
	CONTEXTO = '/pid',
	CLAIMEMAIL = 'email',
	KEYIDTOKEN = 'id_token',
	KEYACCESSTOKEN = 'access_token',
	KEYASESOR = 'asesor',
	PATHROUTE = 'preventas/vinculacion',
	REGIONPROJECT = '6',
	KEYROLE = 'role',
	PATHLOGOUT = 'preventas/logout',
	FIDUPROVIDER = 'FIDUOCCIDENTE',
	BOPROVIDER = 'BO',
	NOTPROVIDER = 'NO_IDENTIFICADO',
	PROVIDERAPP = 'providerApp',
}

export enum IdentityProviders {
	BANCO = 'BANCO',
	FIDUCIARIA = 'FIDUCIARIA',
}

export enum AttemptsOTP {
	CALL = 3,
	SMS = 2,
}

export enum TypeAlert {
	SUCCESS = 1,
	ERROR = 2,
	INFO = 3,
}

export enum WorkFlow {
	FONDOS = 'FONDOS',
	PREVENTAS = 'PREVENTAS',
	GESTIONVINCULACION = 'GESTIONVINCULACION',
}
