<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{ parametrosModal.titulo }}</h5>
          <button id="btn-cerrar-mx" type="button" class="close" (click)="cerrarModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="contenido-modal" [innerHTML]="parametrosModal.body"></div>
        </div>
        <div class="modal-footer">
          <button id="btn-modal-mc" type="button" class="btn btn-secondary" class="btn btn-primary" (click)="cerrarModal()">Cerrar</button>
          <button id="btn-modal-lbl" *ngIf="parametrosModal.label !== false" type="button" >{{ parametrosModal.label }}</button>
        </div>
      </div>
    </div>
  </div>