import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constantes } from '../../../app/shared/enums';
import { AuthenticationService } from '../../../app/shared/services/index';

@Component({
	selector: 'app-initial',
	template: '',
	styles: [],
})
export class InitialComponent implements OnInit {
	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
	) {}

	ngOnInit() {
		sessionStorage.setItem(
			Constantes.KEYROLE,
			this.authenticationService.getPreferredRole(),
		);
		this.router.navigateByUrl('/preventas/vinculacion');
	}
}
