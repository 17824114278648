import { GetProfilingService } from './../../shared/services/get-profile-questions/get-profile-questions.service';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Injectable } from '@angular/core';
import * as pageActions from '../actions/index';
import { of } from 'rxjs';

@Injectable()
export class ProfileQuestionsEffects {
	getProfilesAndQuestions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(pageActions.getProfilesAndQuestions),
			mergeMap(() =>
				this.getprofilingService.getProfile().pipe(
					switchMap((x) => [
						pageActions.setQuestions({ questions: x.questions }),
						pageActions.setProfiles({ profiles: x.profiles }),
					]),
					catchError((err) =>
						of(pageActions.setAllProductsError({ payload: err })),
					),
				),
			),
		),
	);

	constructor(
		private actions$: Actions,
		private getprofilingService: GetProfilingService,
	) {}
}
