import { EstadosRespuestaSocket } from './../../enums/estados.enum';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class ProductDataService {
	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
		private http: HttpClient,
	) {}

	public getActiveProductsByRol(): Observable<any> {
		const rol = this.authenticationService.getPreferredRole();
		if (rol) {
			return this.http.get(`${environment.backEndUrl}/fondos/${rol}`);
		} else {
			this.router.navigate(['/mensaje', EstadosRespuestaSocket.NOROL]);
		}
	}
}
