import { Component, OnInit, Input } from '@angular/core';

declare let $: any;

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('parametrosModal') parametrosModal: any;

	modalActivo: boolean;
	constructor() {
		this.parametrosModal = {};
		this.modalActivo = false;
	}

	ngOnInit() {}

	abrirModal() {
		$('#exampleModalCenter').modal('show');
		return true;
	}

	cerrarModal() {
		$('#exampleModalCenter').modal('hide');
		return false;
	}

	validarContenido() {
		return this.parametrosModal.body;
	}
}
