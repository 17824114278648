import { IAppState } from '../../store/reducers/app.reducer';

import { createReducer, on } from '@ngrx/store';
import * as presalesActions from '../../store/actions/index';

export interface IPresalesState {
	project: any;
}

export interface IPresalesStateExt extends IAppState {
	project: IPresalesState;
}

export const initialPresalesState: IPresalesState = {
	project: null,
};

const presalesReducerLazy = createReducer(
	initialPresalesState,
	on(presalesActions.setProject, (state, { project }) => ({
		...state,
		project: { ...project },
	})),
);

export function presalesReducer(state, action) {
	return presalesReducerLazy(state, action);
}
