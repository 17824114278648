import { Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const backEndUrl = environment.backEndUrl;

@Injectable({
	providedIn: 'root',
})
export class GetProfilingService {
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};
	profileQuestions: any;

	private profileQuestionsUrl = `${backEndUrl}/getProfilesAndQuestions`;
	constructor(private http: HttpClient) {}

	getProfile(): Observable<any> {
		return this.http.get(this.profileQuestionsUrl);
	}
}
