import { IAppState } from '../../store/reducers/app.reducer';

import { createReducer, on } from '@ngrx/store';
import * as pageActions from '../../store/actions/index';

export interface IState {
	product: {
		code: string;
	};
	products: any[];
	error: any;
	legal: any[];
}

export interface IPagesState extends IAppState {
	pages: IState;
}

export const initialState: IState = {
	product: {
		code: null,
	},
	products: [],
	error: null,
	legal: null,
};

const pagesReducerLazy = createReducer(
	initialState,
	on(pageActions.setProductCode, (state, { product }) => ({
		...state,
		product: { ...product },
	})),
	on(pageActions.setAllProducts, (state, { products }) => ({
		...state,
		products: [...products],
	})),
	on(pageActions.setLegalConditions, (state, { legal }) => ({
		...state,
		legal: [...legal],
	})),
	on(pageActions.setAllProductsError, (state, { payload }) => ({
		...state,
		error: {
			url: payload.url,
			name: payload.name,
			message: payload.message,
		},
	})),
);

export function pagesReducer(state, action) {
	return pagesReducerLazy(state, action);
}
