import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormMemoryStorageService } from '@avaldigitallabs/adl-form';
import { Store } from '@ngrx/store';
import { newProduct } from '../../../app/store/actions/index';
import { IProfilingStateExt } from '../../../app/store/reducers/index';
import { WebSocketService } from './websocket/web-socket.service';
import { ResetAppService } from '@avaldigitallabs/focc-pid-lib-vinculaciones-digitales';

@Injectable({
	providedIn: 'root',
})
export class ClearAppService {
	constructor(
		private router: Router,
		private fs: FormMemoryStorageService,
		private webSocketService: WebSocketService,
		private store: Store<IProfilingStateExt>,
		private resetAppSvc: ResetAppService,
	) {}

	nuevaVinculacion() {
		sessionStorage.removeItem('generarOtp');
		sessionStorage.removeItem('generarVinculacion');
		sessionStorage.removeItem('financiera');
		sessionStorage.removeItem('timestampProduct');

		this.fs.form.removeControl('trabajo-data');
		this.fs.form.removeControl('location-data');
		this.fs.form.removeControl('domicilio-data');
		this.fs.form.removeControl('financiera-data');
		this.fs.form.removeControl('person-data');
		this.fs.form.removeControl('selectProject-data');

		this.webSocketService.disconnect();
		this.resetAppSvc.reset(false);

		this.store.dispatch(newProduct());
		this.router.navigate(['/inicio']);
	}

	logOut() {
		sessionStorage.removeItem('asesor');
		sessionStorage.removeItem('state');
	}
}
