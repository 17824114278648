import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileModel } from '../../../shared/models/index';
import { environment } from '../../../../environments/environment';
import Engine from 'json-rules-engine-simplified';

@Injectable({
	providedIn: 'root',
})
export class ProfilinEngineService {
	constructor(private http: HttpClient) {}

	public getProfileClient(score: number): Observable<any> {
		return new Observable((observer) => {
			this.http.get(environment.rutaRulesEngine).subscribe((reglas) => {
				const profileClient: ProfileModel = new ProfileModel();

				const engine = new Engine(reglas);
				const formData = { score };

				engine.run(formData).then((events) => {
					events.map((response) => {
						profileClient.code = response.code;
						profileClient.name = response.name;
						observer.next(profileClient);
					});
				});
			});
		});
	}
}
