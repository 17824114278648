import { environment } from './../../../environments/environment';
import { AppService } from './../../app.service';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from '@avaldigitallabs/focc-pid-commons-lib';

@Injectable()
export class SessionValidateInterceptor implements HttpInterceptor {
	claims: any = null;
	authHeader: any = null;

	constructor(
		private oauthService: OAuthService,
		private appService: AppService,
		private loaderService: LoaderService,
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		this.claims = this.oauthService.getIdentityClaims();

		if (request.url.indexOf('/oauth2/token') < 0) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
				},
			});

			if (
				!this.oauthService.hasValidIdToken() &&
				request.url.search(environment.authUrl) === -1
			) {
				this.oauthService.logoutUrl = this.oauthService.loginUrl.replace(
					'oauth2/authorize',
					`logout?client_id=${this.oauthService.clientId}&logout_uri=${this.oauthService.postLogoutRedirectUri}`,
				);
				this.oauthService.logOut();
			}
		}

		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => event),
			catchError((httpErrorResponse) =>
				this.handleAuthError(request, httpErrorResponse),
			),
		);
	}

	private handleAuthError(request: any, httpErrorResponse: HttpErrorResponse) {
		const response = of(null);

		switch (httpErrorResponse.status) {
			case 0: // unknow
				this.loaderService.toggleLoader(false);
				this.appService.navigateTo('/');
				break;
			case 401: // Expired token
				this.appService.navigateTo('/');
				break;
			default:
				this.appService.navigateTo('/');
				break;
		}

		return response;
	}
}
