import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FreshchatService {
	init(chatParam: any) {
		(window as any).fcWidget.init({
			token: chatParam.token,
			host: chatParam.host,
		});
	}
}
