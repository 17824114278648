import { LegalModel } from './../../shared/models/legal.model';
import { createAction, props } from '@ngrx/store';

/**
 * Set product selected in store
 */
export const setProductCode = createAction(
	'[Product] Set Product Code',
	props<{
		product: {
			name: string;
			code: string;
			urlProspecto: string;
			urlReglamento: string;
			description: string;
		};
	}>(),
);

/**
 * Get all products of service
 */
export const getAllProducts = createAction('[Product] Get All Products');

/**
 * Set productos and save in store
 */
export const setAllProducts = createAction(
	'[Product] Set All Products',
	props<{ products: any[] }>(),
);

/**
 * Set legal conditions in store
 */
export const setLegalConditions = createAction(
	'[Legal] Set All Legal Generics',
	props<{ legal: LegalModel[] }>(),
);

/**
 * Error set productos and save in store
 */
export const setAllProductsError = createAction(
	'[Product] Set All Products Error',
	props<{ payload: any }>(),
);

export const newProduct = createAction('[Product] New Product from front');
