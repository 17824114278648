import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AuthGuardService } from './shared/services/auth-guard/auth-guard.service';
import { InitialComponent } from './pages/initial/initial.component';

const routes: Routes = [
	{
		path: '',
		component: LoginPageComponent,
		data: { pos: 0 },
	},
	{
		path: 'inicio',
		component: InitialComponent,
		canActivate: [AuthGuardService],
		data: { pos: 0 },
	},
	{
		path: 'preventas',
		loadChildren: () =>
			import('./features/vinculacion-lib/vinculacion-lib.module').then(
				(m) => m.VinculacionLibModule,
			),
		canActivate: [AuthGuardService],
	},
	{
		path: '**',
		component: LoginPageComponent,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
			relativeLinkResolution: 'legacy',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
	routes;
}
