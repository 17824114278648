import { environment } from './../../environments/environment';
import { AppSettingsService } from './../shared/services/app-settings.service';
import { HttpClientModule } from '@angular/common/http';
import { ParametrosService } from './../shared/services/parametros.service';
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginPageComponent } from './login-page/login-page.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { ValidarpepComponent } from './validarpep/validarpep.component';
import { ValidaridentidadComponent } from './validaridentidad/validaridentidad.component';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmarIdentidadComponent } from './confirmar-identidad/confirmar-identidad.component';
import { ADLFormModule } from '@avaldigitallabs/adl-form';
import { InformacionLocacionComponent } from './informacion-locacion/informacion-locacion.component';
import { ProductoComponent } from './producto/producto.component';
import { InformacionDomicilioComponent } from './informacion-domicilio/informacion-domicilio.component';
import { InformacionTrabajoComponent } from './informacion-trabajo/informacion-trabajo.component';
import {
	InputModule,
	AddressInputModule,
	OtpFieldsModule,
} from '@avaldigitallabs/focc-pid-commons-lib';
import { InformacionFinancieraComponent } from './informacion-financiera/informacion-financiera.component';
import { ConfirmacionComponent } from './confirmacion/confirmacion.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localEs from '@angular/common/locales/es';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { SeleccionProductoComponent } from './seleccion-producto/seleccion-producto.component';

import { StoreModule } from '@ngrx/store';
import { pagesReducer } from '../store/reducers/index';
import { CrearProductosComponent } from './crear-productos/crear-productos.component';
import { SeleccionProductoPerfilComponent } from './seleccion-producto-perfil/seleccion-producto-perfil.component';
import { TealiumUtagService } from '../core/services/index';
import { InitialComponent } from './initial/initial.component';

@NgModule({
	declarations: [
		LoginPageComponent,
		ValidarpepComponent,
		ValidaridentidadComponent,
		ConfirmarIdentidadComponent,
		InformacionLocacionComponent,
		ProductoComponent,
		InformacionDomicilioComponent,
		InformacionTrabajoComponent,
		InformacionFinancieraComponent,
		ConfirmacionComponent,
		SeleccionProductoComponent,
		CrearProductosComponent,
		SeleccionProductoPerfilComponent,
		InitialComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		HttpClientModule,
		SharedModule,
		CoreModule,
		ReactiveFormsModule,
		ADLFormModule,
		InputModule,
		AddressInputModule,
		NgxMaskModule.forRoot(),
		BrowserAnimationsModule,
		OtpFieldsModule,
		RecaptchaV3Module,
		StoreModule.forFeature('pages', pagesReducer),
	],
	providers: [
		ParametrosService,
		AppSettingsService,
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.siteKeyRecaptchaV3,
		},
		TealiumUtagService,
	],
	entryComponents: [],
})
export class PagesModule {}

registerLocaleData(localEs, 'es-CO');
