import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AppSettingsService {
	rutaArchivo: string;

	constructor(private http: HttpClient) {
		this.rutaArchivo = environment.rutaAppsettings;
	}

	public getJSON(rutaArchivo: string = null): Observable<any> {
		if (rutaArchivo != null) {
			this.rutaArchivo = rutaArchivo;
		} else {
			this.rutaArchivo = '';
		}
		return this.http.get(this.rutaArchivo);
	}
}
