import { createAction, props } from '@ngrx/store';

/**
 * Set project in store
 */
export const setProject = createAction(
	'[Presales] Set Project',
	props<{
		project: any;
	}>(),
);
