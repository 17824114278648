import { createAction, props } from '@ngrx/store';
import { ProfileModel, QuestionModel } from '../../shared/models/index';

/**
 * Set questions in store
 */
export const setQuestions = createAction(
	'[Profiling] Set Questions',
	props<{
		questions: QuestionModel[];
	}>(),
);

/**
 * Get all questions
 */
export const getAllQuestions = createAction('[Profiling] Get All Questions');

/**
 * Set profiles in store
 */
export const setProfiles = createAction(
	'[Profiling] Set Profiles',
	props<{
		profiles: ProfileModel[];
	}>(),
);

/**
 * Get all profiles
 */
export const getAllProfiles = createAction('[Profiling] Get All Profiles');

/**
 * Get Questions and profiles
 */
export const getProfilesAndQuestions = createAction(
	'[Profiling] Get Profiles and questions',
);

/**
 * Get Profile client
 */
export const getProfileClient = createAction(
	'[Profiling] Get Profile Client',
	props<{
		scoreClient: number;
	}>(),
);

/**
 * Set profile client
 */
export const setProfileClient = createAction(
	'[Profiling] Set Profile Client',
	props<{
		profileClient: ProfileModel;
	}>(),
);

/**
 * Set selected profile
 */
export const setProfileSelected = createAction(
	'[Profiling] Set Selected Profile',
	props<{
		profileSelected: ProfileModel;
	}>(),
);
