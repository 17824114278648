import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	public loggedIn = null;
	private logTime = null;
	private timer = null;

	private componentMethodCallSource = new Subject<any>();

	// eslint-disable-next-line @typescript-eslint/member-ordering
	componentMethodCalled$ = this.componentMethodCallSource.asObservable();

	constructor(private router: Router) {}

	navigateTo(route: string) {
		this.router.navigate([route]);
	}

	changeLogued(time) {
		this.logTime = time;
		this.validateSession();
	}

	resetTime() {
		window.clearTimeout(this.timer);
		this.validateSession();
	}

	validateSession() {
		if (this.logTime !== null && this.logTime !== undefined) {
			this.loggedIn = true;
			const timeInSeconds = parseInt(this.logTime, 0) * 60000;
			this.timer = window.setTimeout(() => {
				this.componentMethodCallSource.next();
			}, timeInSeconds);
		}
	}
}
