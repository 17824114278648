import { LoaderLibModule } from '@avaldigitallabs/focc-pid-commons-lib';
import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { BrowserTokenStoreService } from './shared/services/authentication/browser-token-store.service';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { EffectsAarray } from './store/effects/index';
import { appReducers } from './store/reducers/index';
import { persistMetaReducer } from './store/metareducers/persist.meta.reducer';
import { PreSalesComponent } from './features/pre-sales/pre-sales.component';
import { VinculacionLibComponent } from './features/vinculacion-lib/vinculacion-lib.component';
import { Constantes } from './shared/enums';
import { VINCULACION_CONFIG } from '@avaldigitallabs/focc-pid-lib-vinculaciones-digitales';

export const metaReducers: MetaReducer[] = [persistMetaReducer];

@NgModule({
	declarations: [
		AppComponent,
		PageNotFoundComponent,
		PreSalesComponent,
		VinculacionLibComponent,
	],
	imports: [
		LoaderLibModule,
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		PagesModule,
		CoreModule,
		OAuthModule.forRoot(),
		StoreModule.forRoot(appReducers, { metaReducers }),
		EffectsModule.forRoot(EffectsAarray),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
		}),
	],
	providers: [
		{
			provide: OAuthStorage,
			useClass: BrowserTokenStoreService,
		},
		{
			provide: VINCULACION_CONFIG,
			useValue: {
				pathRoute: Constantes.PATHROUTE,
			},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
