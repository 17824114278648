import { ProductDataService } from './../../shared/services/product-data/product-data.service';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Injectable } from '@angular/core';
import * as pageActions from '../actions/index';
import { of } from 'rxjs';

@Injectable()
export class ProductEffects {
	loadAllProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(pageActions.getAllProducts),
			mergeMap(() =>
				this.productDataSvc.getActiveProductsByRol().pipe(
					map((items) =>
						pageActions.setAllProducts({ products: items.fondos }),
					),
					catchError((err) =>
						of(pageActions.setAllProductsError({ payload: err })),
					),
				),
			),
		),
	);
	constructor(
		private actions$: Actions,
		private productDataSvc: ProductDataService,
	) {}
}
