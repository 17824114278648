import { Injectable } from '@angular/core';
import { AnaliticaModel } from './../../shared/models/analitica.model';
import { FormMemoryStorageService } from '@avaldigitallabs/adl-form';

@Injectable({
	providedIn: 'root',
})
export class AnaliticaService {
	analitica: AnaliticaModel;

	constructor(protected fs: FormMemoryStorageService) {
		this.analitica = new AnaliticaModel();
	}

	getSerialGoogleAnalytics() {
		try {
			if (document.cookie.indexOf('_ga=') > -1) {
				const gaCookie = document.cookie
					.split('_ga=')[1]
					.split(';')[0]
					.split('.');
				return gaCookie[2] + '.' + gaCookie[3];
			}
		} catch (e) {
			return 'Error GA cookie data';
		}
	}

	createAnalitycObject() {
		const asesor = JSON.parse(sessionStorage.getItem('asesor'));
		const timestampProduct =
			this.fs.form.get('person-data') !== null
				? this.fs.form.get('person-data').get('timestampProduct').value
				: Date.now();

		this.analitica.sessionId = this.getSerialGoogleAnalytics();
		this.analitica.clientIdType = 'CC';
		this.analitica.clientId =
			this.fs.form.get('person-data') !== null
				? this.fs.form.get('person-data').get('document').value
				: null;
		this.analitica.advisorId = asesor.id;
		this.analitica.channel = 'PID App';
		this.analitica.transactionId =
			this.analitica.clientId +
			'-' +
			this.analitica.advisorId +
			'-' +
			timestampProduct;
		this.analitica.userAgent = navigator.userAgent;

		return this.analitica;
	}
}
