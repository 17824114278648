import { environment } from './../environments/environment';
import { Component, HostListener, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { AuthenticationService } from './shared/services/authentication/authentication.service';
import { FreshchatService } from './shared/services/freshchat/freshchat.service';

@Component({
	selector: 'app-focc-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	showLoader: boolean;
	b: string;
	s: any;
	h: any;
	freschat: any;
	trackingCode: string;
	public chatParam: {
		token: any;
		host: any;
	};
	constructor(
		private appService: AppService,
		private authService: AuthenticationService,
		public freshchatService: FreshchatService,
	) {
		this.appService.componentMethodCalled$.subscribe(() => {
			this.authService.logOut();
		});
		this.trackingCode = '0';
	}
	@HostListener('click', ['$event.target'])
	onClick() {
		this.appService.resetTime();
	}
	ngOnInit() {
		this.trackingCode = environment.gAtrackCode;
		this.loadGA();

		this.chatParam = {
			token: environment.freshChatToken,
			host: environment.freshChatHost,
		};
		this.freshchatService.init(this.chatParam);
	}

	loadGA() {
		if (this.trackingCode === '0') {
			return false;
		} else {
			this.s = document.createElement('script') as any;
			// eslint-disable-next-line max-len
			this.s.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${environment.gAtrackCode}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NGTPZHB');`;
			// eslint-disable-next-line max-len
			this.b = `<!-- Google Tag Manager (noscript) --><noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NGTPZHB&gtm_auth=${environment.gAtrackCode}&gtm_cookies_win=x"height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript><!-- End Google Tag Manager (noscript) -->`;
			document.getElementsByTagName('head')[0].appendChild(this.s);
			document.getElementById('googleTagManager').innerHTML = this.b;
		}
	}
}
