import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { settingAuthConfig } from '../../models/auth/auth.config';
import { Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { Owner } from '../../models/owner/owner';
import { WebSocketService } from '../websocket/web-socket.service';
import { IdentityProviders, Constantes } from './../../enums/estados.enum';
import { environment } from './../../../../environments/environment';
import { LoaderService } from '@avaldigitallabs/focc-pid-commons-lib';
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	public authHeader = null;
	public currentManager: Owner;
	private claims = null;
	private autoCounter = 0;
	constructor(
		private oauthService: OAuthService,
		private router: Router,
		private loaderService: LoaderService,
		private webSocketSvc: WebSocketService,
	) {
		this.configureWithNewConfigApi();
	}
	public login(identityProvider) {
		this.oauthService.customQueryParams =
			identityProvider === IdentityProviders.BANCO
				? environment.customQueryParamsBO
				: environment.customQueryParams;
		this.oauthService.initLoginFlow();
	}
	public logOut() {
		this.webSocketSvc.disconnect();
		this.loaderService.toggleLoader(true);
		this.handleLogout();
	}
	public verifySession(): Observable<boolean> {
		return of(this.validateAuthToken()).pipe(
			mergeMap((isValidAuthToken) => this.getClaims(isValidAuthToken)),
			mergeMap((claimsExists) => this.setManagerData(claimsExists)),
		);
	}
	public getRoles() {
		return this.oauthService.getIdentityClaims()['cognito:roles'].map((rol) => {
			return rol.split('/').pop();
		});
	}
	public getPreferredRole() {
		return this.oauthService
			.getIdentityClaims()
			['cognito:preferred_role'].split('/')
			.pop();
	}
	public getEmail() {
		return this.oauthService.getIdentityClaims()[Constantes.CLAIMEMAIL];
	}

	public getNit() {
		var nit = this.claims['nickname'];
		if (nit != undefined) {
			nit = nit.substr(1);
		}
		return nit;
	}

	public getEmails() {
		var emails = this.claims['middle_name'];
		var array_emails: any;
		if (emails != undefined) {
			emails = emails.substr(1);
			array_emails = emails.split(';');
		}
		return array_emails;
	}

	public getConstructor() {
		var name_company: any;
		if (this.claims['address'] !== undefined) {
			name_company = this.claims['address'].formatted;
			name_company = name_company.substr(1);
		}
		return name_company;
	}

	public setManagerData(claimsExists: boolean): Observable<boolean> {
		if (claimsExists) {
			this.currentManager = !this.currentManager
				? {
						email: this.claims.email,
						firstName: this.claims.given_name + ' ' + this.claims.family_name,
						id: 59082,
						// eslint-disable-next-line indent, @typescript-eslint/indent
				  }
				: this.currentManager;
			if (!this.currentManager.firstName) {
				this.currentManager.firstName = this.currentManager.email;
			}

			const currentAsesor: any = {
				id: this.currentManager.id,
				email_adviser: this.currentManager.email.toLowerCase(),
				name_adviser: this.currentManager.firstName,
				nit_constructor: this.getNit() ? this.getNit() : 1,
				name_constructor: this.getConstructor() ? this.getConstructor() : '',
				copy_emails: this.getEmails() ? this.getEmails() : [],
			};
			sessionStorage.setItem('asesor', JSON.stringify(currentAsesor));
			return of(true);
		} else {
			of(false);
		}
	}
	public isPreferredRole(role: string): boolean {
		return this.getPreferredRole() === role;
	}
	private validateAuthToken(): boolean {
		this.claims = this.oauthService.getIdentityClaims();
		this.getUserProvider();
		this.autoCounter++;
		if (
			!this.claims &&
			this.autoCounter > 2 &&
			!/code/.test(window.location.href)
		) {
			this.router.navigate(['/']);
		}
		this.authHeader = {
			headers: new HttpHeaders({
				Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
			}),
		};
		return this.claims && !!this.oauthService.getAccessToken();
	}
	private getClaims(isValidAuthToken: boolean): Observable<boolean> {
		if (!isValidAuthToken) {
			const uri = window.location.href;
			const result = uri.match(/customErrorFOCC-(.*?)\./);
			if (result && result.length > 0) {
				return of(null);
			}
			return this.oauthService.events.pipe(
				filter((oAuthEvent) => this.validateAuthToken()),
				mergeMap((_) => of(!!this.claims)),
			);
		} else {
			return of(isValidAuthToken);
		}
	}
	private handleLogout() {
		this.oauthService.logoutUrl = this.oauthService.loginUrl.replace(
			'oauth2/authorize',
			`logout?client_id=${this.oauthService.clientId}&logout_uri=${this.oauthService.postLogoutRedirectUri}`,
		);
		this.oauthService.logOut();
	}
	private configureWithNewConfigApi() {
		this.oauthService.configure(settingAuthConfig());
		this.oauthService.setupAutomaticSilentRefresh();
		this.oauthService.loadDiscoveryDocumentAndTryLogin();
	}
	private getUserProvider() {
		let provider = 'default';

		if (
			this.claims !== null &&
			this.claims.identities !== undefined &&
			this.claims.identities.length > 0
		) {
			provider = this.claims.identities[0].providerName;
		}

		const providerApp =
			provider === environment.customQueryParams.identity_provider
				? Constantes.FIDUPROVIDER
				: provider === environment.customQueryParamsBO.identity_provider
				? Constantes.BOPROVIDER
				: Constantes.NOTPROVIDER;

		sessionStorage.setItem(Constantes.PROVIDERAPP, providerApp);
	}
}
