import { environment } from './../../../../environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

export function settingAuthConfig(): AuthConfig {
	const authConfig: AuthConfig = {
		issuer: environment.authUrl,
		strictDiscoveryDocumentValidation: false,
		redirectUri: environment.redirectUri,
		clientId: environment.clientId,
		scope: environment.scope,
		responseType: environment.responseType,
		showDebugInformation: environment.showDebugInformation,
		sessionChecksEnabled: environment.sessionChecksEnabled,
		postLogoutRedirectUri: environment.postLogoutRedirectUri,
	};
	return authConfig;
}
