export class AnaliticaModel {
	sessionId: string;
	clientIdType: string;
	clientId: number;
	advisorId: string;
	channel: string;
	transactionId: string;
	userAgent: string;

	constructor() {
		this.sessionId = null;
		this.clientIdType = null;
		this.clientId = null;
		this.advisorId = null;
		this.channel = null;
		this.transactionId = null;
		this.userAgent = null;
	}
}
