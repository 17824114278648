import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable, Subject, timer } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../../environments/environment';
import { filter } from 'rxjs/operators';
import { AnaliticaService } from '../analitica.service';
import { EstadosRespuestaSocket } from '../../enums/estados.enum';
@Injectable({
	providedIn: 'root',
})
export class WebSocketService {
	private socket$: WebSocketSubject<any>;
	private urlSocket: string;
	private messagesSubject$ = new Subject();
	private numMaxRetry: number = 6;
	private subsTimer$: any;
	private subjectTimer = new Subject<number>();

	constructor(
		private router: Router,
		private oauthService: OAuthService,
		private analitica: AnaliticaService,
	) {
		this.urlSocket = environment.urlSocket;
	}
	public connect() {
		if (this.tokenValidator()) {
			if (!this.socket$ || this.socket$.closed) {
				this.socket$ = this.getNewWebSocket();
				this.socket$.pipe(filter((m) => m.pid_response !== 'pong')).subscribe(
					(response) => {
						this.messagesSubject$.next(response);
					},
					(err) => {
						this.validarError(err);
					},
				);

				this.subsTimer$ = timer(1_000, 60_000);
				this.subjectTimer = this.subsTimer$.subscribe((response) => {
					if (this.socket$) {
						this.socket$.next({ action: 'ping' });
					}
				});
			}
		}
	}

	public listenSocket$(): Observable<any> {
		this.connect();
		return this.messagesSubject$.asObservable();
	}
	public send(data: any) {
		if (data.client != null && !data.client.middle_name) {
			delete data.client.middle_name;
		}
		data.contentType = 'application/json';
		data.analytics = this.analitica.createAnalitycObject();
		this.socket$.next(data);
	}
	public disconnect() {
		if (this.socket$) {
			this.socket$.complete();
			this.socket$ = undefined;
			this.subjectTimer.unsubscribe();
		}
	}
	public tokenValidator(): boolean {
		if (!this.oauthService.hasValidIdToken()) {
			this.oauthService.logoutUrl = this.oauthService.loginUrl.replace(
				'oauth2/authorize',
				`logout?client_id=${this.oauthService.clientId}&logout_uri=${this.oauthService.postLogoutRedirectUri}`,
			);
			this.oauthService.logOut();
			return false;
		} else {
			return true;
		}
	}
	public validarError(err: any) {
		// se hace un desborde por un error no identificado
		this.disconnect();
		this.router.navigate(['/mensaje', EstadosRespuestaSocket.DESCONEXIONSVC]);
	}

	private getNewWebSocket() {
		return webSocket({
			url: this.urlSocket,
			protocol: [
				'v1.pid.fiduoccidente.com',
				this.oauthService.getIdToken(),
				this.oauthService.getAccessToken(),
			],
			openObserver: {
				next: () => {},
			},
			closeObserver: {
				next: (event) => {},
			},
			closingObserver: {
				next: () => {},
			},
		});
	}
}
