import { ParametersModel } from './../models/parameters.model';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

const backEndUrl = environment.backEndUrl;

import {
	HttpClient,
	HttpHeaders,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ParametrosService {
	parametros: ParametersModel;
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};
	private parametrosUrl = `${backEndUrl}/parametros`;

	constructor(private http: HttpClient) {}

	getParametros(): Observable<ParametersModel> {
		if (this.parametros != null) {
			return of(this.parametros);
		}

		return this.http.get(this.parametrosUrl).pipe(
			mergeMap((parameters) => this.returnParameters(parameters)),
			catchError(this.handleError),
		);
	}

	returnParameters(response): Observable<ParametersModel> {
		const parametrosAll = response.parameters;
		this.parametros = new ParametersModel();
		this.parametros.tipoDocumento = parametrosAll.filter(
			(x) => x.keyParameter === 'TIPO_DOCUMENTO',
		)[0].items;

		this.parametros.ciudad = parametrosAll.filter(
			(x) => x.keyParameter === 'CIUDAD',
		)[0].items;

		this.parametros.clienteAceptacion = parametrosAll.filter(
			(x) => x.keyParameter === 'CLIENTE_ACEPTACION',
		)[0].items;

		this.parametros.pepPregunta = parametrosAll.filter(
			(x) => x.keyParameter === 'PEP_PREGUNTA',
		)[0].items;

		this.parametros.textoModales = parametrosAll.filter(
			(x) => x.keyParameter === 'TEXTO_MODALES',
		)[0].items;

		this.parametros.nacionalidad = parametrosAll.filter(
			(x) => x.keyParameter === 'NACIONALIDAD',
		)[0].items;

		this.parametros.profesion = parametrosAll.filter(
			(x) => x.keyParameter === 'PROFESION',
		)[0].items;

		this.parametros.session = parametrosAll.filter(
			(x) => x.keyParameter === 'TIEMPO_SESION',
		)[0].items;

		this.parametros.cargo = parametrosAll.filter(
			(x) => x.keyParameter === 'CARGO',
		)[0].items;

		this.parametros.ciiu = parametrosAll.filter(
			(x) => x.keyParameter === 'CIIU',
		)[0].items;

		return of(this.parametros);
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}
}
