import { isNullOrUndefined } from 'util';
import { environment } from './../../../environments/environment';
import { AppSettingsService } from './../../shared/services/app-settings.service';
import { Router } from '@angular/router';
import { ModalComponent } from './../../shared/components/modal/modal.component';
import { AuthenticationService } from './../../shared/services/authentication/authentication.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IdentityProviders, WorkFlow } from './../../shared/enums/estados.enum';
import { TealiumUtagService } from '../../core/services/index';
import { Constantes } from '../../../app/shared/enums';
import { LoaderService } from '@avaldigitallabs/focc-pid-commons-lib';
import {
	NgbModal,
	NgbModalOptions,
	NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { MaintenanceService } from '../../shared/services';

@Component({
	selector: 'app-focc-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
	@ViewChild('modal') modal: ModalComponent;
	@ViewChild('mantenimientoModal', { static: true }) mantenimientoModal: any;

	public modalRef: NgbModalRef;
	public listMaintenanceStatus: any[];
	parametrosModal: any;
	parametros: any;
	displayBrowser: boolean;
	banco: string = IdentityProviders.BANCO;
	fiduciaria: string = IdentityProviders.FIDUCIARIA;

	get modalOptions(): NgbModalOptions {
		return {
			backdrop: 'static',
			backdropClass: 'customBackdrop',
			scrollable: true,
			centered: true,
			size: 'sm',
		};
	}

	get workFlow() {
		return WorkFlow;
	}

	constructor(
		protected authService: AuthenticationService,
		private loaderService: LoaderService,
		private router: Router,
		private appSettings: AppSettingsService,
		private tealiumSvc: TealiumUtagService,
		public modalSvc: NgbModal,
		public maintenanceSvc: MaintenanceService,
	) {
		this.parametrosModal = {};
		this.parametros = [];
		this.displayBrowser = true;
	}

	async ngOnInit() {
		this.tealiumSvc.view({
			tealium_event: Constantes.VIEW,
			event_name: Constantes.INIT,
			pagePath: this.router.url,
		});

		this.parametros = await this.appSettings
			.getJSON(environment.rutaParametros)
			.toPromise();

		const terminos: any = this.parametros.items.filter(
			(x) => x.value === 'termsYCond',
		)[0];

		if (terminos !== null && terminos !== undefined) {
			this.parametrosModal = {
				titulo: 'Términos y condiciones',
				body: terminos.description,
				label: false,
			};
		} else {
			this.parametrosModal = {};
		}
		this.loaderService.toggleLoader(false);

		this.maintenanceSvc.getMaintenanceStatus().subscribe((response) => {
			this.listMaintenanceStatus = response;
		});
	}

	public onLogin(identityProvider, workFlow: string) {
		const workFlowSel = this.listMaintenanceStatus.find(
			(x) => x.flowName === workFlow,
		);
		if (workFlowSel.flowStatus === 'N') {
			this.openMantenimientoMdl();
		} else {
			this.authService.login(identityProvider);
		}
	}

	toogleModal() {
		this.modal.abrirModal();
	}

	closeBrowser() {
		this.displayBrowser = false;
	}

	openMantenimientoMdl() {
		this.modalRef = this.modalSvc.open(
			this.mantenimientoModal,
			this.modalOptions,
		);
	}

	closeMantenimientoMdl() {
		this.modalRef.close();
	}
}
