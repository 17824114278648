import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

const backEndUrl = environment.backEndUrl;

@Injectable({
	providedIn: 'root',
})
export class ValidarOtpService {
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};
	private otpoUrl = `${backEndUrl}/validarotp`;

	constructor(private http: HttpClient) {}

	postValidarotp(body) {
		return this.http
			.post(this.otpoUrl, body)
			.pipe(retry(1), catchError(this.errorHandl));
	}

	errorHandl(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			errorMessage = error.error.message;
		} else {
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		return throwError(errorMessage);
	}
}
