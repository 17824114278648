import { AuthenticationService } from './../authentication/authentication.service';
import { Constantes } from '../../enums/index';
import {
	Injectable,
	ComponentFactoryResolver,
	ViewContainerRef,
} from '@angular/core';
@Injectable({
	providedIn: 'root',
})
export class MenuSwitchService {
	constructor(
		private cfr: ComponentFactoryResolver,
		private authenticationSvc: AuthenticationService,
	) {}

	async loadComponent(vcr: ViewContainerRef) {
		const { MenuVinculacionComponent } = await import(
			// eslint-disable-next-line comma-dangle
			`./../../../core/menu-vinculacion/menu-vinculacion.component`
		);
		const { MenuComponent } = await import(
			// eslint-disable-next-line comma-dangle
			`./../../../core/menu/menu.component`
		);

		vcr.clear();

		const component: any = this.authenticationSvc.isPreferredRole(
			Constantes.PROFILECOORDVINCU,
		)
			? MenuVinculacionComponent
			: MenuComponent;

		return vcr.createComponent(this.cfr.resolveComponentFactory(component));
	}
}
