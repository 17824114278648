import { IAppState } from '../../store/reducers/app.reducer';

import { createReducer, on } from '@ngrx/store';
import * as profilingActions from '../../store/actions/index';
import { ProfileModel, QuestionModel } from '../../shared/models/index';
import { Constantes } from '../../shared/enums';

export interface IProfilingState {
	questions: QuestionModel[];
	profiles: ProfileModel[];
	profileClient: ProfileModel;
	profileSelected: ProfileModel;
}

export interface IProfilingStateExt extends IAppState {
	profiling: IProfilingState;
}

export const initialProfilingState: IProfilingState = {
	questions: [],
	profiles: [],
	profileClient: { name: Constantes.DEFECTPROFILE },
	profileSelected: { name: Constantes.DEFECTPROFILE },
};

const profilingReducerLazy = createReducer(
	initialProfilingState,
	on(profilingActions.setQuestions, (state, { questions }) => ({
		...state,
		questions: [...questions],
	})),
	on(profilingActions.setProfiles, (state, { profiles }) => ({
		...state,
		profiles: [...profiles],
	})),
	on(profilingActions.setProfileClient, (state, { profileClient }) => ({
		...state,
		profileClient: { ...profileClient },
	})),
	on(profilingActions.setProfileSelected, (state, { profileSelected }) => ({
		...state,
		profileSelected: { ...profileSelected },
	})),
);

export function profilingReducer(state, action) {
	return profilingReducerLazy(state, action);
}
