export class ParametersModel {
	tipoDocumento: any[];
	ciudad: any[];
	clienteAceptacion: any[];
	pepPregunta: any[];
	textoModales: any[];
	nacionalidad: any[];
	profesion: any[];
	session: number;
	cargo: any[];
	ciiu: any[];
	oficina: any[];

	constructor() {
		this.tipoDocumento = null;
		this.ciudad = null;
		this.clienteAceptacion = null;
		this.pepPregunta = null;
		this.textoModales = null;
		this.nacionalidad = null;
		this.profesion = null;
		this.session = 1;
		this.cargo = null;
		this.ciiu = null;
		this.oficina = null;
	}
}
