import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class MaintenanceService {
	private url: string;

	private httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'x-api-key': environment.maintenanceToken,
		}),
	};

	constructor(private http: HttpClient) {
		this.url = environment.backEndUrl;
	}

	getMaintenanceStatus(): Observable<any> {
		return this.http.get(`${this.url}/estadoflujos`, this.httpOptions);
	}
}
