import { ToastAlertDownComponent } from './components/toast-alert-down/toast-alert-down.component';
import { ModalComponent } from './components/modal/modal.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SessionValidateInterceptor } from './interceptors/session-validate.interceptor';
import { ValidarInputDirective } from './directives/validar-input.directive';
import { LongitudInputDirective } from './directives/longitud-input.directive';
import { AppService } from '../app.service';
import { ADLFormModule } from '@avaldigitallabs/adl-form';
import { RedirectButtonComponent } from './components/redirect-button/redirect-button.component';
import { CookieService } from 'ngx-cookie-service';
import { ToastAlertComponent } from './components/toast-alert/toast-alert.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { InactivityComponent } from './components/inactivity/inactivity.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

export function playerFactory() {
	return player;
}

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		ADLFormModule,
		NgbModalModule,
		LottieModule.forRoot({ player: playerFactory }),
	],
	declarations: [
		ModalComponent,
		ValidarInputDirective,
		LongitudInputDirective,
		RedirectButtonComponent,
		ToastAlertComponent,
		ToastAlertDownComponent,
		InactivityComponent,
	],
	exports: [
		ModalComponent,
		ValidarInputDirective,
		LongitudInputDirective,
		RedirectButtonComponent,
		ToastAlertComponent,
		ToastAlertDownComponent,
		InactivityComponent,
	],
	providers: [
		AuthenticationService,
		AuthGuardService,
		AppService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SessionValidateInterceptor,
			multi: true,
		},
		CookieService,
	],
	entryComponents: [],
})
export class SharedModule {}
