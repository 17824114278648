import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthenticationService,
	) {}

	canActivate(): Observable<boolean> {
		return this.authService.verifySession().pipe(
			tap((isVerified) => {
				if (!isVerified) {
					this.router.navigate(['/']);
				}
				return isVerified;
			}),
		);
	}
}
