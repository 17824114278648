import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class BrowserTokenStoreService implements OAuthStorage {
	constructor(private cookieService: CookieService) {}

	getItem(key: string): string {
		return this.readCookie(key);
	}
	removeItem(key: string): void {
		this.removeCookie(key);
	}
	setItem(key: string, data: string): void {
		this.writeCookie(key, data);
	}

	readCookie(key: string): string {
		return this.cookieService.get(key);
	}

	removeCookie(key: string) {
		this.cookieService.delete(key);
	}

	writeCookie(key: string, data: string) {
		return this.cookieService.set(key, data, {
			secure: environment.cookieSecure,
		});
	}
}
