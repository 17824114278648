import { MenuSwitchService } from './../shared/services/menu-switch/menu-switch.service';
import { ProfileMenuDirective } from './../shared/directives/profile-menu.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FormContainerComponent } from './form-container/form-container.component';
import { MenuComponent } from './menu/menu.component';
import { MenuVinculacionComponent } from './menu-vinculacion/menu-vinculacion.component';

@NgModule({
	imports: [CommonModule],
	exports: [HeaderComponent, FooterComponent, FormContainerComponent],
	declarations: [
		HeaderComponent,
		FooterComponent,
		FormContainerComponent,
		ProfileMenuDirective,
		MenuComponent,
		MenuVinculacionComponent,
	],
	entryComponents: [MenuComponent, MenuVinculacionComponent],
	providers: [MenuSwitchService],
})
export class CoreModule {}
